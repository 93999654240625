/* Import Tailwind CSS classes */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Google Fonts */

@import url("https://fonts.googleapis.com/css2?family=PT+Mono&family=Raleway&display=swap");

/* Custom CSS */

.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

@layer base {
  body {
    @apply font-raleway;
  }

  h1 {
    @apply font-mono;
  }
}
